<template>
	<div ref="loaderRef"
		 class="loader" />
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { delay } from '~/utils'
import { useMetaStore } from '~~/stores/metaStore'
process.client && gsap.registerPlugin(ScrollTrigger)

const route = useRoute()
const metaStore = useMetaStore()
const { $bus } = useNuxtApp()
const loaderRef = ref(null)
const startLoaderHasRun = ref(false)

function startLoader() {
	metaStore.loaderActive = true
	startLoaderHasRun.value = true

	gsap.set(loaderRef.value, {
		autoAlpha: 1,
		backgroundColor: metaStore.activePageColors.cursorBackgroundColor,
		translateX: '-50%',
		translateY: '-50%',
		scale: 0,
		width: () => Math.max(window.innerWidth, window.innerHeight) * 1.4,
		height: () => Math.max(window.innerWidth, window.innerHeight) * 1.4,
	})

	gsap.to(loaderRef.value, {
		scale: 1,
		duration: metaStore.transitionDuration / 1000,
		ease: 'circ.out',

		onComplete: () => {
			$bus.$emit('menu-close')
			$bus.$emit('scroll-to-top', true)
			$bus.$emit('loader-finished')

			metaStore.loaderActive = false
		}
	})
}

function finishLoader() {
	if (metaStore.loaderActive || metaStore.pageLoading) {
		delay(50).then(() => {
			finishLoader()
		})
	} else if (startLoaderHasRun.value) {
		gsap.timeline()
			.to(loaderRef.value, {
				scale: 0,
				duration: metaStore.transitionDuration / 1000,
				ease: 'circ.in',
			})
			.to(loaderRef.value, {
				autoAlpha: 0,
				duration: 0.2,
				ease: 'expo.in',
				onComplete: () => {
					if (route.name !== 'index') $bus.$emit('header-lock', false)
					$bus.$emit('loaderFinished')
					metaStore.pageLoading = false
				}
			}, '-=0.2')

		metaStore.loaderActive = false
	}
}

onMounted(() => {
	$bus.$on('page-start', startLoader)
	$bus.$on('page-mounted', finishLoader)
})

onBeforeUnmount(() => {
	$bus.$off('page-start', startLoader)
	$bus.$off('page-mounted', finishLoader)
})
</script>

<style lang="scss">
.loader {
	position: fixed;
	transition: background-color 0.3s ease-in-out;
	will-change: width, height, background-color, transform;
	top: 50%;
	left: 50%;
	width: 0vw;
	height: 0vh;
	z-index: 850;
	border-radius: 50%;
}
</style>