<template>
	<footer ref="footerRef"
			:style="styles">
		<div class="container-fluid footer">
			<div class="row footer-top-row">
				<div class="col-xs-12 col-xl-7 footer-button-wrapper">
					<baseButton classes="big secondary inverted footer-cta-button"
								:cursor="{ custom: true, text: 'HYPE!' }"
								:magnetic-strength="0.65"
								:book-button="true">
						Start a project
					</baseButton>
				</div>
				<div class="col-xs-12 col-sm-5 col-md-3 col-xl-2 footer-social-links">
					<ul class="nolist">
						<li>
							<NuxtLink to="https://www.instagram.com/superodd.studio"
									  rel="nofollow"
									  target="_blank">
								Instagram
							</NuxtLink>
						</li>
						<li>
							<NuxtLink to="https://www.facebook.com/superodd.studio"
									  rel="nofollow"
									  target="_blank">
								Facebook
							</NuxtLink>
						</li>
						<li>
							<NuxtLink to="https://www.behance.net/superodd"
									  rel="nofollow"
									  target="_blank">
								Behance
							</NuxtLink>
						</li>
						<li>
							<NuxtLink to="https://www.linkedin.com/company/superodd"
									  rel="nofollow"
									  target="_blank">
								Linkedin
							</NuxtLink>
						</li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-7 col-md-9 col-xl-3 footer-acknowledgement">
					<AboriginalFlag />
					<p>
						Superodd respectfully acknowledge the Traditional Owners of the land, the Boon Wurrung and Woiwurrung (Wurundjeri) peoples of the Kulin Nation and pay respect to their Elders
						past, present and emerging.
					</p>
				</div>
			</div>
			<div class="row footer-bottom-row">
				<div class="col-xs-6 col-sm-6 footer-faces">
					<IconFaces />
				</div>
				<div class="col-xs-6 col-sm-6 footer-copyright">
					<p>© Superodd {{ currentYear }}</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import IconFaces from '~/assets/images/icon-faces.svg'
import AboriginalFlag from '~/assets/images/icon-aboriginal-flag.svg'

if (process.client) gsap.registerPlugin(ScrollTrigger)

const footerRef = ref(null)
const currentYear = new Date().getFullYear()
const styles = {
	'--foreground-color': '#fdfdfd',
	'--background-color': '#121212'
}
</script>

<style lang="scss">
footer {
	min-height: 50vh;
	box-sizing: border-box;
	font-size: rem-calc(20);
	width: 100%;
	background-color: $black;
	margin-top: -10px;
	padding-top: rem-calc(80);
	color: $white;
	display: grid;

	.footer {
		height: 100%;
		display: flex;
		align-self: stretch;
		flex-flow: column nowrap;
		justify-content: space-between;
		padding-bottom: rem-calc(80);

		.footer-top-row {
			margin-bottom: rem-calc(80);

			@include for-devices-above(sm) {
				margin-bottom: rem-calc(100);
			}

			@include for-devices-above(xl) {
				margin-bottom: rem-calc(160);
			}

			.footer-button-wrapper {
				// display: flex;
				// justify-content: space-around;
				margin-bottom: rem-calc(48);

				@include for-devices-above(md) {
					justify-content: flex-start;
				}

				@include for-devices-above(sm) {
					margin-bottom: rem-calc(80);
				}

			}


			.footer-social-links {
				margin-bottom: rem-calc(30);

				ul {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;

					@include for-devices-above(sm) {
						flex-flow: column nowrap;
					}

					li {

						a {
							display: inline-block;
							color: $white;
							@include textBody;
							@include standard-link;
							box-shadow: none;
							padding-bottom: rem-calc(5);
							margin-bottom: rem-calc(5);

							@include for-devices-above(sm) {
								margin-bottom: rem-calc(10);
							}

							@include for-devices-above(md) {
								flex-grow: unset;

								&:first-of-type {
									padding-top: 0;
								}
							}
						}
					}
				}
			}

			.footer-acknowledgement {
				display: block;

				svg {
					width: 100%;
					max-width: rem-calc(75);
					margin-bottom: rem-calc(25);
				}

				p {
					line-height: 150%;
				}

				@include for-devices-below(md) {
					text-align: center;

					p {
						text-align: left;
					}
				}

				@include for-devices-between(md, xl) {
					display: flex;
					flex-flow: row-reverse nowrap;
					gap: rem-calc(30);

					svg {
						align-self: flex-start;
					}

					p {
						flex-basis: 1;
					}
				}

			}
		}

		.footer-bottom-row {

			.footer-faces,
			.footer-copyright {
				color: $white;
				display: flex;

			}

			.footer-copyright {
				flex-flow: column nowrap;
				justify-content: space-around;

				p {
					text-align: right;
				}
			}
		}
	}
}
</style>
