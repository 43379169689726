<template>
  <div
    class="layout"
    :class="{ 'cursor-activated': !cursorDisabled && cursorActivated }"
    :style="colors"
  >
    <TheCursor key="the-cursor" />
    <TheHeader key="the-header" />
    <TheMenu />
    <slot />
    <TheFooter key="the-footer" />

    <TheCookieConsent key="the-cookie-consent" />
    <TheTeleportArea key="the-teleport" />
    <TheLoader key="the-loader" />
  </div>
</template>

<script setup>
import { useMetaStore } from '~~/stores/metaStore'
import { storeToRefs } from 'pinia'

const metaStore = useMetaStore()
const { $bus } = useNuxtApp()

const { cursorActivated, cursorDisabled } = storeToRefs(metaStore)

const colors = ref({})

$bus.$on('active-page-colors-changed', () => setPageColors(metaStore.activePageColors))

function setPageColors(newColors) {
	colors.value = {
		'--background-color': newColors.backgroundColor,
		'--foreground-color': newColors.foregroundColor,
		'--details-color': newColors.detailsColor,
		'--cursor-background-color': newColors.cursorBackgroundColor,
		'--cursor-foreground-color': newColors.cursorForegroundColor,
	}
}

onMounted(() => {
	setPageColors(metaStore.activePageColors)
})

onBeforeUnmount(() => {
	$bus.$off('header-render', forceRerender)
	$bus.$off('active-page-colors-changed', () => setPageColors(metaStore.activePageColors))
})
</script>

<style lang="scss">
#smooth-content {
	background-color: $white;
}

.cursor-activated:hover {
	* {
		cursor: none !important;
	}

	*:fullscreen {
		font-size: 3em;
		text-align: center;
		cursor: auto !important;
	}
}
</style>