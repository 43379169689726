<template>
	<section v-if="showCookieConsent"
			 ref="sectionRef"
			 class="cookie-consent"
			 role="dialog">
		<div ref="containerRef"
			 class="cookie-consent-container">
			<div class="cookie-consent-container-text">
				<h3 ref="titleRef"
					class="cookie-consent-title">
					Mmm, yummy cookies
				</h3>
				<span ref="messageRef"
					  class="cookie-consent-message">
					We use cookies to make sure you get the best experience on our site.
					<span>Click "Accept" or
						<NuxtLink :to="href"
								  :target="target"
								  :rel="rel"
								  aria-label="Learn more about cookies"
								  class="cookie-consent-link nowrap">
							{{ linkLabel }}
						</NuxtLink>
					</span>
				</span>
			</div>
			<div ref="buttonRef"
				 class="cookie-consent-container-button">
				<baseButton classes="secondary inverted cookie-consent-compliance"
							@click="dismiss">
					{{ buttonLabel }}
				</baseButton>
			</div>
		</div>
	</section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { delay } from '~/utils'
if (process.client) gsap.registerPlugin(ScrollTrigger, SplitText)

const props = defineProps({
	// default
	transition: {
		type: String,
		default: 'cookie-consent-transition'
	},
	title: {
		type: String,
		default: 'Mmm, yummy cookies'
	},
	message: {
		type: String,
		default: 'We use cookies to make sure you get the best experience on our site.'
	},

	// button
	linkLabel: {
		type: String,
		default: 'learn more about cookies'
	},
	buttonLabel: {
		type: String,
		default: 'Accept'
	},
	href: {
		type: String,
		default: 'https://cookiesandyou.com'
	},
	target: {
		type: String,
		default: '_blank'
	},
	rel: {
		type: String,
		default: 'noopener'
	},

	// cookie
	cookieName: {
		type: String,
		default: 'cookieconsent_status'
	},
	cookiePath: {
		type: String,
		default: '/'
	},
	cookieDomain: {
		type: String,
		default: null
	},
	cookieExpiryDays: {
		type: Number,
		default: 365
	}
})
const route = useRoute()
const sectionRef = ref(null)
const containerRef = ref(null)
const titleRef = ref(null)
const messageRef = ref(null)
const buttonRef = ref(null)

const showCookieConsent = ref(false)
const startedOnIndex = ref(false)
const splitlines = reactive({})
const triggers = {}
let $q, tl

function dismiss() {
	// tl.timeScale(4).reverse()
	const height = sectionRef.value.offsetHeight
	gsap.timeline()
		.set(sectionRef.value, { height })
		.to(containerRef.value, {
			autoAlpha: 0,
			duration: 0.3,
			ease: 'expo.inOut'
		})
		.to(sectionRef.value, {
			width: 4,
			duration: 0.75,
			ease: 'expo.out',
		}, '-=0.15')
		.to(sectionRef.value, {
			height: 0,
			duration: 0.3,
			ease: 'expo.out',
			onComplete: setConsent
		})
}

function setConsent() {
	setCookie(props.cookieName, true, props.cookieExpiryDays, props.cookieDomain, props.cookiePath)

	showCookieConsent.value = false
	if (startedOnIndex.value) ScrollTrigger.getById('revealCookieConsentAnims').kill()
}

function getCookie(name) {
	const value = `;${document.cookie}`
	// Chrome 80+ adds a space before the cookie name
	const parts = value.includes(`;${name}=`) ? value.split(`;${name}=`) : value.split(`; ${name}=`)

	return parts.length !== 2 ? undefined : parts.pop().split(';').shift()
}

function setCookie(name, value, expiryDays, domain, path) {
	const exdate = new Date()
	exdate.setDate(exdate.getDate() + (expiryDays || 365))
	const cookie = [
		`${name}=${value}`,
		`expires=${exdate.toUTCString()}`,
		`path=${(path || '/')}`,
		'sameSite=strict'
	]

	if (domain) { cookie.push(`domain=${domain}`) }

	document.cookie = cookie.join(';')
}

function revealCookieConsentAnims() {
	$q = gsap.utils.selector(sectionRef.value)

	revealConsent()

	if (route.name === 'index' && showCookieConsent.value) {
		startedOnIndex.value = true

		triggers.revealCookies = ScrollTrigger.create({
			id: 'revealCookieConsentAnims',
			trigger: sectionRef.value,
			end: () => window.innerHeight * 1.8,
			scrub: true,
			invalidateOnRefresh: true,
			onLeave: () => {
				tl.timeScale(1).play()
			},
		})
	} else if (showCookieConsent.value) {
		delay(2000).then(() => {
			tl.timeScale(1).play()
		})
	}
}

function revertText() { splitlines.outerSplit.revert(); splitlines.innerSplit.revert() }

function revealConsent() {
	tl = gsap.timeline({ paused: true, onReverseComplete: setConsent })

	if (Object.prototype.hasOwnProperty.call(splitlines, 'innerSplit')) revertText()

	splitlines.innerSplit = new SplitText([titleRef.value, messageRef.value], { type: 'lines', linesClass: 'split-child' })
	splitlines.outerSplit = new SplitText([titleRef.value, messageRef.value], { type: 'lines', linesClass: 'split-parent' })

	gsap.set($q('.split-parent'), { overflow: 'hidden', position: 'relative', })
	gsap.set($q('.split-child'), { yPercent: 100, })

	tl
		.to(sectionRef.value, {
			height: 'auto',
			duration: 0.75,
			ease: 'power4.out'
		})
		.to(splitlines.innerSplit.lines, {
			yPercent: 0,
			duration: 1.5,
			stagger: 0.15,
			ease: 'expo.out',
		}, '-=0.25')
		.from(buttonRef.value, {
			xPercent: 50,
			autoAlpha: 0,
			duration: 1.5,
			ease: 'expo.out',
		}, '-=1.25')
}


function init() {
	// when document fonts have loaded
	document.fonts.ready.then(() => {
		showCookieConsent.value && revealCookieConsentAnims()
	})
}

onMounted(() => {
	showCookieConsent.value = !getCookie(props.cookieName)
	useAnims(init, triggers)
})

</script>

<style lang="scss" scoped>
.cookie-consent {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: $half-gutter-width;
	bottom: $half-gutter-width;
	color: $white;
	z-index: 500;
	background: $black;
	border-radius: 9999px;
	height: 0;
	overflow: hidden;
	// visibility: hidden;
	width: calc(100% - #{$gutter-width});

	@include for-devices-above(xl) {
		width: calc(75% - #{$half-gutter-width});
	}

	@include for-devices-above(xxl) {
		width: calc(58.33333333% - #{$half-gutter-width});
	}

	&-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		gap: $half-gutter-width;
		width: 100%;
		padding: rem-calc(24 30 24 40);

		@include for-devices-above(sm) {
			// flex-flow: row nowrap;
			gap: $gutter-width;
		}

		@include for-devices-above(md) {
			padding: rem-calc(34 40 34 50);
		}

		// @include for-devices-above(lg) {
		// padding: rem-calc(42 55);
		// }

		// @include for-devices-above(lg) {
		// 	padding: rem-calc(44 48 57 48);
		// }

		// @include for-devices-above(xl) {
		// 	padding: rem-calc(44 72 57 72);
		// }
	}

	&-title,
	&-message,
	&-compliance {
		@include textBodySmall;
		// visibility: hidden;
	}

	&-title {
		margin: 0;
		display: block;
		font-weight: 600;
		display: none;

		@include for-devices-above(sm) {
			display: block;
		}

		@include for-devices-above(xxl) {
			max-width: unset;
		}
	}

	&-message {
		display: block;
		// max-width: rem-calc(700);
	}


	&-text {
		flex-flow: column wrap;
		// text flex-flow styles
	}

	&-button {
		// button flex-flow styles
		will-change: transform;
	}
}

// :deep(.button-safe-wrap) {
// 	@include for-devices-above(lg) {
// 		transform: translateX(1.5em);
// 	}
// }

.cookie-consent-transition {
	&-leave-active {
		transition: transform 0.75s;
		transition-timing-function: cubic-bezier(0.75, 0, 0, 1);
	}

	&-leave-to {
		transform: translateY(100%);
	}
}

.cookie-consent-message {
	a {
		@include standard-link;
		color: $white;
	}
}

:deep(.cookie-consent-container-button) {
	.button .text {
		@include textBodySmall;
	}
}
</style>
