<template>
	<div v-show="cursorEnabled"
		 ref="cursorRef"
		 class="cursor"
		 :class="{ filled: isHovering }">
		<div ref="cursorInnerRef"
			 class="cursor-inner">
			<svg ref="cursorSmileyRef"
				 viewBox="0 0 67 67"
				 fill="none"
				 xmlns="http://www.w3.org/2000/svg">
				<path d="M51.1031 38.0996C51.1031 43.6996 46.3031 48.2996 40.4031 48.2996C34.5031 48.2996 29.7031 43.6996 29.7031 38.0996"
					  stroke="currentColor"
					  stroke-width="5" />
				<path d="M29.5 28C30.8807 28 32 26.8807 32 25.5C32 24.1193 30.8807 23 29.5 23C28.1193 23 27 24.1193 27 25.5C27 26.8807 28.1193 28 29.5 28Z"
					  fill="currentColor" />
				<path d="M50.5 28C51.8807 28 53 26.8807 53 25.5C53 24.1193 51.8807 23 50.5 23C49.1193 23 48 24.1193 48 25.5C48 26.8807 49.1193 28 50.5 28Z"
					  fill="currentColor" />
			</svg>
			<span v-show="textVisible"
				  ref="cursorTextRef">{{ cursorText }}</span>
		</div>
	</div>
</template>

<script setup>
import { gsap } from 'gsap'
import { useMetaStore } from '~~/stores/metaStore'
import { storeToRefs } from 'pinia'

const props = defineProps({
	allowOnMobile: {
		type: Boolean,
		default: false
	}
})

const metaStore = useMetaStore()
const route = useRoute()
const { $bus } = useNuxtApp()

const cursorRef = ref(null)
const cursorInnerRef = ref(null)
const cursorSmileyRef = ref(null)
const cursorTextRef = ref(null)
const links = ref([])

const { cursorDisabled } = storeToRefs(metaStore)
const cursorDefaultWidth = 0
const cursorDuration = 0.15
const cursorHoverWidth = computed(() => cursorCustom.value ? 67 : 25)

const cursorCustom = ref(false)
const cursorText = ref('')
const smileyVisible = ref(false)
const textVisible = ref(false)
const isHovering = ref(false)
let intent, attrs, xTo, yTo

const cursorEnabled = computed(() => {
	if (process.server) return false

	const touch = isTouchDevice()

	return !cursorDisabled.value && (props.allowOnMobile || !touch)
})

watch(() => route.path, () => {
	delay(metaStore.getDelay).then(() => {
		destroyMouse(cursorEnabled.value)
	})
})

function isTouchDevice() {
	return ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
}

function initMouse() {
	if (metaStore.cursorActivated) destroyMouse(true)

	else {
		xTo = gsap.quickSetter(cursorRef.value, 'x', 'px')
		yTo = gsap.quickSetter(cursorRef.value, 'y', 'px')

		gsap.set([cursorRef.value, cursorInnerRef.value, cursorSmileyRef.value, cursorTextRef.value], { xPercent: -50, yPercent: -50 })
		gsap.set([cursorSmileyRef.value, cursorTextRef.value], { scale: 0 })

		attachMouseEvents()
	}

	$bus.$on('cursor-text', (text) => cursorText.value = text)
	$bus.$on('cursor-refresh', () => destroyMouse(true))

	cursorDoLeave()
}

function attachMouseEvents() {
	window.addEventListener('mousemove', cursorMove)
	window.addEventListener('mousedown', cursorScaleDown, false)
	window.addEventListener('mouseup', cursorScaleUp, false)

	links.value = [...document.querySelectorAll('[data-cursor-custom], [data-cursor-hover], .button, button, a, label')]
	links.value.forEach(link => {
		link.addEventListener('mouseenter', cursorHover, false)
		link.addEventListener('mouseleave', cursorLeave, false)
		// link.addEventListener('click', cursorClick, false)
	})

	metaStore.cursorActivated = true
}

function destroyMouse(refresh = false) {
	window.removeEventListener('mousemove', cursorMove)
	window.removeEventListener('mousedown', cursorScaleDown, false)
	window.removeEventListener('mouseup', cursorScaleUp, false)

	links.value.forEach(link => {
		link.removeEventListener('mouseenter', cursorHover, false)
		link.removeEventListener('mouseleave', cursorLeave, false)
		// link.removeEventListener('click', cursorClick, false)
	})

	metaStore.cursorActivated = false
	refresh && attachMouseEvents()
}

function cursorMove(e) {
	// logic here for mousemove when hovering over a magnetic element
	cursorDoMove(e)
}

function cursorHover(e) {
	attrs = e.target.dataset
	isHovering.value = true

	if (Object.prototype.hasOwnProperty.call(attrs, 'cursorCustom')) cursorCustom.value = true
	if (Object.prototype.hasOwnProperty.call(attrs, 'cursorText')) cursorText.value = attrs.cursorText

	cursorDoHover()
}

function cursorLeave() {
	cursorDoLeave()
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cursorClick() {
	cursorDoClick()
}

function cursorDoMove(e) {
	xTo(e.clientX)
	yTo(e.clientY)
}

function cursorDoHover() {
	clearTimeout(intent)
	const tl = gsap.timeline()

	if (Object.prototype.hasOwnProperty.call(attrs, 'textInstant')) {
		tl.to(cursorTextRef.value, {
			rotate: -360,
			scale: 1,
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power4.out',
		}, '<')

		textVisible.value = true
	}

	else if (Object.prototype.hasOwnProperty.call(attrs, 'cursorCustom')) {
		tl.to(cursorSmileyRef.value, {
			rotate: -360,
			scale: 1,
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power4.out',
			overwrite: true
		})

		if (Object.prototype.hasOwnProperty.call(attrs, 'cursorText')) {
			intent = setTimeout(() => {
				tl.to([cursorSmileyRef.value], {
					rotate: 0,
					scale: 0,
					duration: 0.25,
					ease: 'power4.out',
					overwrite: true
				}).to(cursorTextRef.value, {
					rotate: -360,
					scale: 1,
					autoAlpha: 1,
					duration: 0.5,
					ease: 'power4.out',
					overwrite: true
				}, '-=0.15')
			}, 1000)

			textVisible.value = true
		}
	}

	tl.to(cursorInnerRef.value, {
		width: cursorHoverWidth.value,
		height: cursorHoverWidth.value,
		duration: cursorDuration,
		ease: 'power4.out',
		overwrite: true
	}, '<')
}

function cursorDoLeave() {
	clearTimeout(intent)

	const tl = gsap.timeline()

	if (smileyVisible.value || textVisible.value) {
		tl.to([cursorSmileyRef.value, cursorTextRef.value], {
			rotate: 0,
			scale: 0,
			autoAlpha: 0,
			duration: 0.5,
			ease: 'power4.out',
			overwrite: true
		})
	}

	tl.to(cursorInnerRef.value, {
		width: cursorDefaultWidth,
		height: cursorDefaultWidth,
		duration: 0.5,
		ease: 'power4.out',
		overwrite: true,
		clearProps: 'background-color'
	}, '<')

	gsap.set([cursorSmileyRef.value, cursorTextRef.value], {
		rotate: -360,
		scale: 0,
	})

	smileyVisible.value = false
	isHovering.value = false
	textVisible.value = false
	cursorText.value = ''
	cursorCustom.value = false
	attrs = null
}

function cursorDoClick() {
	// actions to perform when mouse clicks something
}

function cursorScaleDown() {
	gsap.to(cursorRef.value, {
		scale: 0.75,
		duration: 0.25,
		ease: 'expo.out'
	})
}

function cursorScaleUp() {
	gsap.to(cursorRef.value, {
		scale: 1,
		duration: 0.25,
		ease: 'expo.out'
	})
}

function toggleCursor(refresh) {
	cursorDisabled.value = !cursorDisabled.value
	localStorage.setItem('cursorDisabled', cursorDisabled.value)
	destroyMouse()

	if (cursorDisabled.value) metaStore.cursorActivated = false
	if (refresh) cursorEnabled.value && initMouse()
}

onMounted(() => {
	if (Object.prototype.hasOwnProperty.call(localStorage, 'cursorDisabled')) cursorDisabled.value = JSON.parse(localStorage.getItem('cursorDisabled'))
	if (cursorEnabled.value) initMouse()

	$bus.$on('cursor-toggle', (bool) => toggleCursor(bool))
})

onBeforeUnmount(() => {
	$bus.$off('cursor-toggle', (bool) => toggleCursor(bool))
	$bus.$off('cursor-text', (text) => cursorText.value = text)
	$bus.$off('cursor-refresh', () => destroyMouse(true))

	destroyMouse()
})
</script>

<style lang="scss" scoped>
.cursor {
	width: 15px;
	height: 15px;
	position: fixed;
	top: 0;
	left: 0;
	border: 4px solid var(--cursor-background-color, $black);
	border-radius: 50%;
	pointer-events: none;
	background-color: transparent;
	z-index: 1000;
}

.cursor-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: var(--cursor-background-color, $black);

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		color: var(--cursor-foreground-color, $white);
	}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 1rem;
		font-weight: 700;
		visibility: hidden;
		text-align: center;
		line-height: 1;
		color: var(--cursor-foreground-color, $white);
	}
}
</style>