<template>
	<div id="mainmenu"
		 ref="componentRef"
		 class="main-menu">
		<div ref="backgroundRef"
			 class="circle" />
		<div ref="innerWrapperRef"
			 class="inner-wrapper"
			 :style="{ padding: headerPadding }">
			<div ref="menuContentRef"
				 class="menu-content">
				<nav>
					<ul class="nolist">
						<li>
							<NuxtLink class="main-menu-link first"
									  to="/work"
									  title="Work">
								<span class="link-title">Work</span>
								<div class="link-sub-wrapper">
									<span class="menu-subtitle">Proof is in the pudding</span>
									<span class="arrow-wrapper">
										<div class="arrow">
											<div class="line" />
											<arrowTip class="point" />
										</div>
									</span>
								</div>
							</NuxtLink>
						</li>
						<li>
							<NuxtLink class="main-menu-link"
									  to="/about"
									  title="About">
								<span class="link-title">About</span>
								<div class="link-sub-wrapper">
									<span class="menu-subtitle">The how and why of it all</span>
									<span class="arrow-wrapper">
										<div class="arrow">
											<div class="line" />
											<arrowTip class="point" />
										</div>
									</span>
								</div>
							</NuxtLink>
						</li>
						<li>
							<NuxtLink class="main-menu-link"
									  to="/contact"
									  title="Contact">
								<span class="link-title">Contact</span>
								<div class="link-sub-wrapper">
									<span class="menu-subtitle">Let's talk</span>
									<span class="arrow-wrapper">
										<div class="arrow">
											<div class="line" />
											<arrowTip class="point" />
										</div>
									</span>
								</div>
							</NuxtLink>
						</li>
					</ul>
				</nav>
			</div>
		</div>
		<div v-show="showToggle"
			 ref="cursorSwitchRef"
			 class="wrapwrap">
			<div class="switch-button">
				<input id="cursor-switch"
					   type="checkbox"
					   :checked="!cursorDisabled"
					   class="checkbox"
					   @click="handleToggleCursor">
				<div class="knobs" />
				<div class="layer" />
			</div>
			<label for="cursor-switch"
				   class="switch-label">Custom cursor <span>{{ cursorDisabled ? 'disabled' : 'enabled' }}</span></label>
		</div>
	</div>
</template>

<script setup>
import { gsap } from 'gsap'
import SplitText from 'gsap/SplitText'
import arrowTip from '~/assets/images/icon-caret-right.svg'
import { useMetaStore } from '~~/stores/metaStore'
import { storeToRefs } from 'pinia'

if (process.client) gsap.registerPlugin([SplitText])

const { $bus } = useNuxtApp()
const metaStore = useMetaStore()

const componentRef = ref(null)
const innerWrapperRef = ref(null)
const menuContentRef = ref(null)
const backgroundRef = ref(null)
const cursorSwitchRef = ref(null)

const { cursorDisabled, activePageColors, headerHeight } = storeToRefs(metaStore)

const showToggle = computed(() => {
	if (process.server) return false

	return !isTouchDevice()
})

const prevHeaderPrimary = ref('')
const prevHeaderSecondary = ref('')
const splitlines = reactive({})
let $q, menutl, intent

const headerPadding = computed(() => {
	if (headerHeight.value) {
		return headerHeight.value + (headerHeight.value / 4) + 'px 0px' + headerHeight.value + 'px 0px'
	} else {
		return '455px 0px 0px 0px'
	}
})

function isTouchDevice() {
	return ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
}

function handleToggleCursor() {
	const refresh = !cursorDisabled.value

	$bus.$emit('cursor-toggle', refresh)
}

function setupTimeline() {
	menutl = gsap.timeline({ paused: true, reversed: true, overwrite: true })

	const textTargets = gsap.utils.toArray('.link-title', componentRef.value)
	const subtitles = $q('.menu-subtitle')
	const arrows = $q('.arrow')

	splitlines.innerSplit = new SplitText(textTargets, {
		type: 'lines',
		linesClass: 'split-child'
	})
	splitlines.outerSplit = new SplitText(textTargets, {
		type: 'lines',
		linesClass: 'split-parent'
	})

	menutl
		.set(componentRef.value, {
			display: 'none',
			height: '0px',
			width: '0px',
		})
		.set(componentRef.value, {
			display: 'block',
			height: '100%',
			width: '100%',
		})
		.fromTo(backgroundRef.value, {
			xPercent: 100,
		}, {
			xPercent: 0,
			duration: 0.75,
			ease: 'expo.inOut',
		})
		.call(() => {
			metaStore.menuActive = !metaStore.menuActive
		})
		.from(splitlines.innerSplit.lines, {
			autoAlpha: 0,
			yPercent: 100,
			duration: 1,
			stagger: 0.2,
			ease: 'expo.out'
		}, '-=0.2')

		// move subtitles in from left
		.from(subtitles, {
			autoAlpha: 0,
			x: -50,
			duration: 0.5,
			ease: 'power2.inOut',
		}, '-=0.5')

		.from(arrows, {
			autoAlpha: 0,
			x: 50,
			width: 0,
			duration: 0.5,
			ease: 'power2.inOut',
			clearProps: 'all'
		}, '-=0.5')


		.fromTo($q('.wrapwrap'), {
			autoAlpha: 0,
		}, {
			autoAlpha: 1,
			duration: 1
		}, '-=0.5')
		.fromTo($q('.switch-button'), {
			x: -50,
			scaleY: 0,
		}, {
			x: 0,
			scaleY: 1,
			duration: 0.75,
			stagger: 0.2,
			ease: 'power4.out',
		}, '-=1')
		.fromTo($q('.switch-label'), {
			x: 50,
		}, {
			x: 0,
			duration: 0.75,
			stagger: 0.2,
			ease: 'power4.out',
		}, '-=1')
		.fromTo($q('.knobs'), {
			'--left': '0.25rem',
		}, {
			clearProps: '--left',
			duration: 0.75,
			ease: 'power4.out',
		}, '-=0.5')
}

function toggleMenu() {
	if (typeof intent === 'number') clearTimeout(intent)

	if (menutl && menutl.reversed()) {
		menutl.timeScale(1.2).play()
		intent = setTimeout(openMenu, 200)
		$bus.$emit('scroll-stop')
	} else {
		menutl.timeScale(3).reverse()
		intent = setTimeout(menuClose, 400)
		$bus.$emit('scroll-start')
	}
}

function openMenu() {
	const element = document.getElementById('header-inner')
	const styles = window.getComputedStyle(element)

	prevHeaderPrimary.value = styles.getPropertyValue('--header-primary') || 'clear'
	prevHeaderSecondary.value = styles.getPropertyValue('--header-secondary') || 'clear'

	$bus.$emit('header-change-color', { primary: activePageColors.value.cursorBackgroundColor, secondary: activePageColors.value.cursorForegroundColor })
}

function menuClose() {
	$bus.$emit('header-change-color', { primary: prevHeaderPrimary.value, secondary: prevHeaderSecondary.value })
}

function menuCloseInstantly() {
	menutl && menutl.progress(0, false).reversed(true)

	$bus.$emit('scroll-start')
	$bus.$emit('header-change-color', { primary: prevHeaderPrimary.value, secondary: prevHeaderSecondary.value })

	metaStore.menuActive = false
}

onMounted(() => {
	$q = gsap.utils.selector(componentRef.value)
	useAnims(setupTimeline)

	$bus.$on('menu-toggle', toggleMenu)
	$bus.$on('menu-close', menuCloseInstantly)
})

onBeforeUnmount(() => {
	$bus.$off('menu-toggle', toggleMenu)
	$bus.$off('menu-close', menuCloseInstantly)
})
</script>

<style lang="scss" scoped>
.main-menu {
	position: fixed;
	overflow-x: hidden;
	z-index: 600;
	width: 0;

	.circle {
		background-color: var(--cursor-foreground-color, $white);
		color: var(--cursor-background-color, $black);
		position: absolute;
		will-change: transform;
		width: 100%;
		height: 100%;
	}

	// .circle {
	// 	background-color: var(--cursor-foreground-color, $white);
	// 	color: var(--cursor-background-color, $black);
	// 	position: absolute;
	// 	transform: translate(-50%, -50%);
	// 	transform-origin: top right;
	// 	will-change: height, width, top, left;
	// 	width: 0;
	// 	height: 0;
	// 	border-radius: 50%;
	// }

	.inner-wrapper {
		position: relative;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		@include universal-side-padding;

		.menu-content {
			position: relative;
			width: 100%;
			flex-basis: 91.66666667%;
			max-width: 91.66666667%;

			@include for-devices-above(sm) {
				flex-basis: 83.33333333%;
				max-width: 83.33333333%;
			}

			@include for-devices-above(md) {
				flex-basis: 91.66666667%;
				max-width: 91.66666667%;
			}

			@include for-devices-above(xxl) {
				flex-basis: 83.33333333%;
				max-width: 83.33333333%;
			}

			ul {
				position: relative;
				display: block;

				li {
					position: relative;
					display: block;
					margin-bottom: rem-calc(38);

					.main-menu-link {
						position: relative;
						display: block;
						will-change: transform;
						color: var(--cursor-background-color, $black);
						text-decoration: none;
						font-size: clamp(rem-calc(85), 13vmin, rem-calc(300));

						@media (max-height: 620px) {
							font-size: clamp(rem-calc(30), 8vmin, rem-calc(100));
						}

						@media (min-height: 621px) and (max-height: 965px) {
							font-size: clamp(rem-calc(30), 10vmin, rem-calc(100));
						}

						@media (min-height: 1440px) and (min-width: 940px) {
							font-size: clamp(rem-calc(30), 50vmin, rem-calc(200));
						}

						@media (min-height: 1440px) {
							font-size: clamp(rem-calc(30), 20vmin, rem-calc(200));
						}

						&.first {
							font-family: 'SuperStrange';
						}

						&:not(:first-of-type) {
							margin-top: -4px;
						}

						.link-title {}

						.link-sub-wrapper {
							display: flex;
							flex-flow: row nowrap;
							gap: rem-calc(30);


							.menu-subtitle {
								position: relative;
								// font-size: rem-calc(16.79);
								font-size: clamp(rem-calc(15), 0.275em, rem-calc(30));
								font-family: "SuperSans";
								font-weight: 400;
								// font-variation-settings: 'wght'400;
								color: var(--cursor-background-color, $black);
								line-height: 150%;

								// @include for-devices-above(sm) {
								// 	font-size: rem-calc(25.66);
								// }

								// @include for-devices-above(lg) {
								// 	font-size: rem-calc(32);
								// }
							}

							.arrow-wrapper {
								position: relative;
								flex-grow: 0;
								transition: flex-grow 1s $easeOutQuint;

								.arrow {
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									width: calc(100% - 4px);
									min-width: rem-calc(31);
									will-change: width;
									height: rem-calc(20);

									.line {
										width: 100%;
										background-color: var(--cursor-background-color, $black);
										float: left;
									}

									.point {
										color: var(--cursor-background-color, $black);
									}
								}
							}
						}

						.strange {
							font-family: 'SuperStrange';
							font-variation-settings: 'wdth' var(--font-width, 100);
						}

						&:hover {
							.arrow-wrapper {
								flex-grow: 1;
							}
						}
					}
				}
			}
		}
	}
}



.wrapwrap {
	visibility: hidden;
	position: absolute;
	bottom: #{$outer-margin};
	left: #{$outer-margin};

	@include for-devices-below(lg) {
		bottom: calc(#{$outer-margin} / 2);
		left: calc(#{$outer-margin} / 2);
	}

	@include for-devices-below(md) {
		bottom: calc(#{$outer-margin} / 3);
		left: calc(#{$outer-margin} / 3);
	}

	display: flex;
	gap: 1em;

	.switch-button {
		position: relative;
		height: rem-calc(32);
		width: rem-calc(50);
		overflow: hidden;
		border-radius: 100px;
		// background-color: $white;
		border: 4px solid var(--cursor-background-color, $black);

		@include for-devices-below(lg) {
			border-width: 3px;
		}

		@include for-devices-below(md) {
			border-width: 2px;
		}

		.checkbox {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			opacity: 0;
			z-index: 3;

			&:active+.knobs:before {
				width: rem-calc(25);
				border-radius: 100px;
			}

			&:checked:active+.knobs:before {
				margin-left: rem-calc(-10);
			}

			&:checked+.knobs {
				--left: #{rem-calc(20)};

				&:before {
					// content: "YES";
					content: '';
					left: var(--left, 20px);
					background-color: var(--cursor-background-color, $black);
				}


				@include for-devices-below(lg) {
					--left: #{rem-calc(22)};

					&:before {
						// content: "YES";
						content: '';
						left: var(--left, 22px);
						background-color: var(--cursor-background-color, $black);
					}
				}

				@include for-devices-below(md) {
					--left: #{rem-calc(23)};

					&:before {
						// content: "YES";
						content: '';
						left: var(--left, 23);
						background-color: var(--cursor-background-color, $black);
					}
				}
			}

			// &:checked~.layer {
			// background-color: $colorLighterGreen;
			// }
		}


		.knobs {
			z-index: 2;
			--left: #{rem-calc(4)};

			&:before {
				// content: "NO";
				content: '';
				box-sizing: border-box;
				position: absolute;
				left: var(--left, 4px);
				top: 50%;
				width: rem-calc(18);
				height: rem-calc(18);
				transform: translateY(-50%);
				background-color: var(--cursor-foreground-color, transparent);
				border: 3px solid var(--cursor-background-color, $black);
				border-radius: 50%;
				transition: all 0.2s ease, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
			}

			@include for-devices-below(lg) {
				--left: #{rem-calc(4)};

				&:before {
					left: var(--left, 4px);
				}
			}

			@include for-devices-below(md) {
				--left: #{rem-calc(4)};

				&:before {
					left: var(--left, 4px);
				}
			}
		}



		@media (min-height: 925px) and (min-width: 1440px) {
			height: rem-calc(42);
			width: rem-calc(74);

			.checkbox {
				&:active+.knobs:before {
					width: rem-calc(40);
				}

				&:checked:active+.knobs:before {
					margin-left: rem-calc(-15);
				}


				&:checked+.knobs {
					--left: 36px;

					&:before {
						left: var(--left, 36px);
					}
				}
			}

			.knobs {
				--left: #{rem-calc(4)};

				&:before {
					width: rem-calc(25);
					height: rem-calc(25);
					transition: all 0.3s ease, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
				}
			}
		}

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			// background-color: #ebf7fc;
			transition: 0.3s ease all;
			z-index: 1;
			border-radius: rem-calc(100);
		}
	}

	.switch-label {
		@include textBody;
		align-self: center;
		color: var(--cursor-background-color, $black);
		line-height: 150%;

		span {
			font-weight: 600;
		}
	}
}

.nopoint {
	pointer-events: none;
}
</style>
