<template>
	<header id="header"
			ref="headerRef">
		<div class="container-fluid">
			<div class="row">
				<div ref="headerWrapperRef"
					 class="header-wrapper col-xs-12"
					 :style="indexCss">
					<div id="header-inner"
						 ref="innerHeaderRef"
						 class="header-inner"
						 :class="{ 'header-inverted': headerInverted }">
						<div id="site-logo"
							 ref="logoRef"
							 class="site-logo">
							<NuxtLink v-if="!error"
									  id="logo-link"
									  to="/"
									  data-cursor-custom
									  data-cursor-text="HOME"
									  title="Go to front page"
									  @click="handleLogoClick">
								<SuperoddLogo />
							</NuxtLink>
							<a v-else
							   id="logo-link"
							   href="/"
							   data-cursor-custom
							   data-cursor-text="HOME"
							   title="Go to front page">
								<SuperoddLogo />
							</a>
						</div>

						<div v-if="!metaStore.error"
							 ref="menuContainerRef"
							 class="main-menu-container">
							<ul v-if="!isMobile">
								<li>
									<NuxtLink to="/work"
											  class="main-menu-link">
										Work
									</NuxtLink>
								</li>
								<li>
									<NuxtLink to="/about"
											  class="main-menu-link">
										About
									</NuxtLink>
								</li>
								<li>
									<NuxtLink to="/contact"
											  class="main-menu-link">
										Contact
									</NuxtLink>
								</li>
								<li>
									<baseButton id="book-call-button"
												class="main-menu-link"
												:cursor="{ custom: true, text: `LET'S TALK`, textInstant: true }"
												:book-button="true">
										Book a call
									</baseButton>
								</li>
							</ul>

							<div v-else
								 class="mobile-container">
								<baseButton id="book-call-button"
											class="main-menu-link"
											:magnetic="false"
											:cursor="{ custom: true, text: `LET'S TALK`, textInstant: true }"
											:book-button="true">
									Book a call
								</baseButton>
								<TheMenuToggle class="main-menu-link" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
import SuperoddLogo from '~/assets/images/logo-main.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useMetaStore } from '~/stores/metaStore'
import { storeToRefs } from 'pinia'
import debounceFn from 'debounce-fn'

process.client && gsap.registerPlugin(ScrollTrigger)

const route = useRoute()
const metaStore = useMetaStore()
const { $bus } = useNuxtApp()
const isMobile = useBreakpoint('below', 'lg')

// refs
const headerRef = ref(null)
const headerWrapperRef = ref(null)
const innerHeaderRef = ref(null)
const logoRef = ref(null)
const menuContainerRef = ref(null)
// vars
const { menuActive, headerHeight, error } = storeToRefs(metaStore)
const headerWidth = ref(0)
const headerTimelineReversed = ref(false)
const headerTimelineActive = ref(false)
const headerInverted = ref(false)
const headerSizeAnimProgress = ref(0)
const screenWidth = ref(0)

const triggers = {}
let $q, revealTL, revealIntent = null

const indexCss = computed(() => { return (isHomepage.value) ? '--foreground-color: #121212' : null })
const isHomepage = computed(() => route.name === 'index')

const logoEase = computed(() => {
	return headerTimelineReversed.value ? 'power4.in' : 'power4.out'
})

const getSizeByScreenWidth = computed(() => {
	if (!process.client) return

	const sizes = {
		xs: { logoWidth: '158px', menuOffset: -45 },
		sm: { logoWidth: '189px', menuOffset: -50 },
		md: { logoWidth: '265px', menuOffset: -54 },
		lg: { logoWidth: '315px', menuOffset: -55 },
		xl: { logoWidth: '315px', menuOffset: -55 },
		xxl: { logoWidth: '315px', menuOffset: -55 }
	}

	switch (true) {
		case screenWidth.value >= 1440:
			return sizes.xxl
		case screenWidth.value >= 1200:
			return sizes.xl
		case screenWidth.value >= 1024:
			return sizes.lg
		case screenWidth.value >= 768:
			return sizes.md
		case screenWidth.value >= 576:
			return sizes.sm
		default:
			return sizes.xs
	}
})

watch(() => route.name, async (newVal) => {
	await cleanup()
	$bus.$emit('header-render')

	if (newVal === 'index' && $q) {
		initHeaderResizeAnim()
		triggers.headerTL.totalProgress(0)
	}
})

function enableBusEvents(bool) {
	if (bool) {
		$bus.$on('header-change-color', (colorObj) => colorHeader(colorObj))
		$bus.$on('header-set-height', storeHeaderHeight)
		$bus.$on('page-start', () => triggers.headerTL?.pause())
		$bus.$on('window-resized', debounceFn(handleWindowResize, { wait: 250 }))
	} else {
		$bus.$off('header-change-color', (colorObj) => colorHeader(colorObj))
		$bus.$off('header-set-height', storeHeaderHeight)
		$bus.$off('page-start', () => triggers.headerTL?.pause())
		$bus.$off('window-resized', debounceFn(handleWindowResize, { wait: 250 }))
	}
}

function colorHeader(colorObj) {
	if (!colorObj) { clearHeaderColor(); return }

	const primary = colorObj?.primary
	const secondary = colorObj?.secondary
	const resetHeaderValues = ['clear', 'reset', 'default']

	if (primary) {
		if (resetHeaderValues.includes(primary)) clearHeaderPrimaryColor()
		else gsap.set(innerHeaderRef.value, { '--header-primary': primary })
	}
	if (secondary) {
		if (resetHeaderValues.includes(secondary)) clearHeaderSecondaryColor()
		else gsap.set(innerHeaderRef.value, { '--header-secondary': secondary })
	}
}

function clearHeaderColor() {
	clearHeaderPrimaryColor()
	clearHeaderSecondaryColor()
}

function clearHeaderPrimaryColor() {
	gsap.set(innerHeaderRef.value, { clearProps: '--header-primary' })
}

function clearHeaderSecondaryColor() {
	gsap.set(innerHeaderRef.value, { clearProps: '--header-secondary' })
}

function storeHeaderHeight() {
	setTimeout(() => {
		console.log('storing header height', headerRef.value?.offsetHeight)
		headerHeight.value = headerRef.value?.offsetHeight
	}, 0)
}

function initRevealHeaderTimeline() {
	revealTL = gsap.timeline()
		.to(logoRef.value, {
			autoAlpha: 1,
			duration: 0.5,
			ease: 'power2.inOut',
		})
		.to($q('.main-menu-link'), {
			autoAlpha: 1,
			duration: 0.5,
			stagger: 0.1,
			ease: 'power2.inOut',
		})
}

async function initHeaderResizeAnim() {
	headerWidth.value = innerHeaderRef.value?.offsetWidth

	await createHeaderTimeline()

	if (isHomepage.value) {
		if (menuActive.value) {
			setTimeout(() => {
				triggers.headerTL.totalProgress(1).reversed(false)
			}, 250)
		} else {
			colorHeader()
		}
	} else {
		await cleanup()
	}
}

async function createHeaderTimeline(reversed = true) {
	triggers.headerTL = gsap.timeline({
		paused: true,
		reversed: reversed,
		overwrite: true,
		scrollTrigger: {
			trigger: headerRef.value,
			start: 'top top',
			end: '+=50%',
			scrub: 0.5,
		},
		onComplete: () => {
			headerTimelineActive.value = false

		},
		onReverseComplete: () => {
			headerTimelineActive.value = false
		},
		defaults: {
			duration: 0.5,
			ease: logoEase.value,
		}
	})

	await attachHeaderTimelineTweens()
}

async function attachHeaderTimelineTweens() {
	await new Promise((resolve) => {
		triggers.headerTL
			.fromTo(logoRef.value, {
				width: () => headerWidth.value,
			}, {
				width: () => getSizeByScreenWidth.value.logoWidth,
			})
			.fromTo($q('.inner'), {
				paddingTop: '6px',
				paddingBottom: '10px',
			}, {
				paddingTop: '2px',
				paddingBottom: '3px',
			}, '<')
			.fromTo(menuContainerRef.value, {
				y: 0,
			}, {
				y: () => getSizeByScreenWidth.value.menuOffset, // accounting for the top padding we remove from the button, making the menu smaller
			}, '<')

		resolve()
	})
}

function handleLogoClick() {
	if (menuActive.value && route.name === 'index') {
		$bus.$emit('menu-toggle')
	} else if (route.name === 'index') {
		$bus.$emit('scroll-to-top')
	}
}

/**
 * Handles the window resize event and performs necessary actions.
 */
async function handleWindowResize() {
	headerWidth.value = innerHeaderRef.value?.offsetWidth
	// Check if the window width has changed
	if (window.innerWidth !== screenWidth.value) {
		screenWidth.value = window.innerWidth

		if (revealIntent) clearTimeout(revealIntent)

		// Reset and pause the reveal timeline
		// required twice for some reason
		revealTL.totalProgress(0).pause()
		initRevealHeaderTimeline()
		revealTL.totalProgress(0).pause()

		// Clean up any previous animations and reinit
		await cleanup(true)
		// await initHeaderResizeAnim()

		if (!menuActive.value && triggers.headerTL) {
			console.log('setting header size anim progress', triggers.headerTL.totalProgress())
			headerSizeAnimProgress.value = triggers.headerTL?.totalProgress()
		}

		revealTL.play(1)
		revealIntent = null

		// revealIntent = setTimeout(() => {
		// 	revealTL.play(1)
		// 	revealIntent = null
		// }, 250)

		storeHeaderHeight()
	}
}

async function cleanup(refresh) {
	await new Promise((resolve) => {
		triggers.headerTL?.totalProgress(1).kill()
		killTriggers(triggers)
		refresh && initHeaderResizeAnim()
		resolve()
	})


}


function initComponent() {
	$q = gsap.utils.selector(headerRef.value)
	headerWidth.value = innerHeaderRef.value?.offsetWidth
	screenWidth.value = window.innerWidth

	storeHeaderHeight()
	initHeaderResizeAnim()
	initRevealHeaderTimeline()
}

function syncLogoSizeToMenuStatus() {
	if (!triggers.headerTL) return

	if (!menuActive.value) {
		headerSizeAnimProgress.value = triggers.headerTL.totalProgress()
		gsap.to(triggers.headerTL, { progress: 1, duration: 1 })
	} else {
		gsap.to(triggers.headerTL, { progress: headerSizeAnimProgress.value, duration: 0.5, ease: 'power4.out' })
	}
}

onMounted(() => {
	useAnims(initComponent, triggers)
	enableBusEvents(true)

	$bus.$on('menu-toggle', syncLogoSizeToMenuStatus)
})

onBeforeUnmount(async () => {
	await cleanup()
	enableBusEvents(false)

	$bus.$off('menu-toggle', syncLogoSizeToMenuStatus)
})
</script>

<style lang="scss"
	   scoped>
	header {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 800;
		transition: all 0.5s $so-ease-out;
		background-color: transparent;
		pointer-events: none;
		// padding: rem-calc(24 0 24 0);

		.site-logo,
		.main-menu-link {
			pointer-events: auto;
			visibility: hidden;
		}

		.header-wrapper {
			width: 100%;
			padding: $half-gutter-width $half-gutter-width 0 $half-gutter-width;

			.header-inner {
				width: 100%;
				display: flex;
				flex-flow: column nowrap;
				// flex-flow: row wrap;
				justify-content: space-between;
				align-items: stretch;
				color: var(--header-primary, $black);
				transition-delay: 0.7s;
				transition: color 0.2s ease-in-out;
				padding: rem-calc(12 0 12 0);

				@include for-devices-above(md) {
					padding: rem-calc(24 0 24 0);
				}

				.site-logo {
					display: block;
					width: 100%;
					will-change: width, opacity;

					a {
						display: block;

						svg {
							display: block;
							width: auto;
							color: inherit;

							path {
								pointer-events: none;
							}
						}
					}
				}

				.main-menu-container {
					margin-left: auto;

					ul {
						@include nolist;
						display: flex;
						flex-flow: row nowrap;
						gap: rem-calc(60);

						li {
							@include textBody;
							display: inline-block;
							display: flex;
							flex-flow: row nowrap;
							align-items: center;
							font-weight: $semiBold;

							a {
								@include standard-link;
								box-shadow: none;
								visibility: hidden;
								transition-property: box-shadow, font-size;

								&.router-link-active {
									box-shadow: 0 4px 0px 0px;
								}
							}
						}
					}
				}

				.mobile-container {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					justify-content: flex-end;
					gap: rem-calc(15);
					will-change: transform;
				}

				:deep(.button) {
					display: block;
					background-color: transparent;
					border-color: var(--header-primary, $black);
					border-width: 4px;

					&:hover {
						.inner .text {
							color: var(--header-secondary, $white);
						}
					}

					.inner {
						.filler {
							background-color: var(--header-primary, $black);
						}

						.text {
							color: var(--header-primary, $black);
							@include textBody;
							font-weight: $semiBold;
							transition: font-size 0.2s ease-in-out;
						}
					}
				}
			}
		}
	}

	.header-hidden {
		* {
			pointer-events: none;
		}
	}
</style>
