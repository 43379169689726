<template>
  <button
    id="hamburger"
    ref="compRef"
    role="button"
    title="Toggle main menu"
    @click.prevent="toggleMainNav"
  >
    <!-- <svg id="burger" width="30" class="openmenu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
			<path class="top" d="M0 9h30v2H0z" />
			<line class="mid" x1="0" y1="15" x2="30" y2="15" stroke="black" stroke-width="2" vector-effect="non-scaling-stroke" />
			<path class="bot" d="M0 19h30v2H0z" />
		</svg> -->

    <svg
      id="burger"
      class="openmenu"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        class="top"
        y1="1.5"
        x2="18"
        y2="1.5"
        stroke="black"
        stroke-width="3"
      />
      <line
        class="mid"
        y1="9.5"
        x2="18"
        y2="9.5"
        stroke="black"
        stroke-width="3"
      />
      <line
        class="bot"
        y1="17.5"
        x2="18"
        y2="17.5"
        stroke="black"
        stroke-width="3"
      />
    </svg>
  </button>
</template>

<script setup>
import { gsap } from 'gsap'

const { $bus } = useNuxtApp()

const compRef = ref(null)

let $q, toggleTL

function toggleMainNav() {
	$bus.$emit('menu-toggle')
	toggleTL.reversed() ? toggleTL.play() : toggleTL.reverse()
}

function toggleTLAnim() {
	toggleTL = new gsap.timeline({ paused: true, reversed: true, defaults: { duration: 0.1, transformOrigin: '50% 50%' } })

	toggleTL
		// .set(compRef.value, { className: "-=closemenu" })
		// .set(compRef.value, { className: "+=openmenu" })
		.to($q('.top'), {
			y: -3,
			ease: 'power2.inOut',
		})
		.to($q('.bot'), {
			y: 3,
			ease: 'power2.inOut',
		}, '<')
		.to($q('.mid'), {
			scaleX: 0.1,
			duration: 0.2,
			ease: 'power2.inOut',
		}, '-=0.1')
		.to($q('.top'), {
			y: 8,
			scaleX: 1.25,
		})
		.to($q('.bot'), {
			y: -8,
			scaleX: 1.25,
		}, '-=0.1')
		.to($q('.top'), {
			rotationZ: 45,
		})
		.to($q('.bot'), {
			rotationZ: -45,
		}, '-=0.1')

}

function resetToggle() {
	toggleTL && toggleTL.progress(0).reversed(true)
}

onMounted(() => {
	$bus.$emit('cursor-refresh')
	$q = gsap.utils.selector(compRef.value)

	toggleTLAnim()

	$bus.$on('menu-close', resetToggle)
})

onBeforeUnmount(() => {
	$bus.$off('menu-close', resetToggle)
})
</script>

<style lang="scss" scoped>
button {
	pointer-events: auto;
	overflow: visible;

	padding: rem-calc(15 0 15 15);

	svg {
		overflow: visible;
		width: rem-calc(24);

		line {
			stroke: var(--header-primary, $black)
		}
	}
}
</style>
